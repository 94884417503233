import React from 'react'
import {Translate} from '@stockfiller/i18n'
import {Box, Button, Typography} from "@mui/material";
import FormFragment from "./FormFragment";

const ForgottenPwdDone = ({onBack, email}) => (
    <FormFragment>
        <Typography variant="h2"><Translate id="forgottenPasswordDoneView.title"/></Typography>
        <Box>
            <Typography><Translate data={{email}} id="forgottenPasswordDoneView.description"/></Typography>
        </Box>
        <Box>
            <Button
                color="primary"
                onClick={onBack}
                type="button"
            ><Translate id="forgottenPasswordDoneView.buttons.back"/></Button>
        </Box>
    </FormFragment>
);

export default ForgottenPwdDone
