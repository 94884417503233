import {assign, createMachine} from 'xstate'
import {changePwdChallenge, forgot, forgotPassword, passwordRules, resetPassword, signIn} from './requests'
import {CHALLENGE_CHANGE_PASSWORD, CHALLENGE_RESET_PASSWORD} from "common/challenge";

export const EVENT_NEW_INPUT = "EVENT_NEW_INPUT"

export const STATE_AUTHENTICATE = "STATE_AUTHENTICATE"
export const STATE_PENDING_AUTHENTICATE = "STATE_PENDING_AUTHENTICATE"
export const STATE_FORGOT_PWD = "STATE_FORGOT_PWD"
export const STATE_PENDING_FORGOT_PWD = "STATE_PENDING_FORGOT_PWD"
export const STATE_FORGOT_PWD_DONE = "STATE_FORGOT_PWD_DONE"
export const STATE_RESET_PWD = "STATE_RESET_PWD"
export const STATE_INIT_RESET_PWD = "STATE_INIT_RESET_PWD"
export const STATE_PENDING_RESET_PWD = "STATE_PENDING_RESET_PWD"
export const STATE_PWD_CHANGE = "STATE_PWD_CHANGE"
export const STATE_INIT_PWD_CHANGE = "STATE_INIT_PWD_CHANGE"
export const STATE_PENDING_PWD_CHANGE = "STATE_PENDING_PWD_CHANGE"
export const STATE_ERROR = "error"
export const STATE_SUCCESS = "STATE_SUCCESS"

export const TRANSITION_PROCEED = "PROCEED";
export const TRANSITION_FORGOTTEN_PASSWORD = "FORGOT_PWD"
export const TRANSITION_AUTHENTICATE = "AUTHENTICATE"

export const isLoadingState = stateValue =>
    stateValue === STATE_PENDING_AUTHENTICATE ||
    stateValue === STATE_PENDING_RESET_PWD ||
    stateValue === STATE_PENDING_PWD_CHANGE ||
    stateValue === STATE_INIT_RESET_PWD ||
    stateValue === STATE_INIT_PWD_CHANGE ||
    stateValue === STATE_PENDING_FORGOT_PWD

const DATA_INPUT_EVENT = {
    [EVENT_NEW_INPUT]: {
        actions: [assign((_, event) => ({[event.field]: event.value}))]
    }
}

const invokeLogin = (originState, action) => ({
    id: `${originState}_postCredentials`,
    src: context => action(context),
    onDone: [
        {
            target: STATE_SUCCESS,
            cond: (_, event) => event.data.redirect,
            actions: [assign((_, event) => ({"redirect": event.data.redirect}))]
        },
        {
            target: STATE_PWD_CHANGE,
            cond: (_, event) => event.data.challenge === CHALLENGE_CHANGE_PASSWORD,
        },
        {
            target: STATE_RESET_PWD,
            cond: (_, event) => event.data.challenge === CHALLENGE_RESET_PASSWORD,
        },
    ],
    onError: [
        {
            target: originState,
            cond: (_, event) => isAuthenticationError(event),
            actions: [assign((_, event) => ({error: event.data.response}))]
        },
        {
            target: STATE_ERROR,
            actions: assign((_, event) => ({error: event.data.response}))
        }
    ]
});

const isAuthenticationError = event => [400, 401, 403].includes(event.data.response.statusCode)

const createAuthenticationMachine = (services, deepLinks, token) => createMachine({
    id: 'AuthenticationMachine',
    initial: 'idle',
    context: {},
    predictableActionArguments: true,
    states: {
        idle: {
            on: {
                ERROR: STATE_ERROR,
                ...deepLinks
            }
        },
        error: {
            type: 'final'
        },
        [STATE_FORGOT_PWD]: {
            on: {
                ...DATA_INPUT_EVENT,
                [TRANSITION_PROCEED]: [{
                    target: STATE_PENDING_FORGOT_PWD,
                    cond: context => context.email,
                    actions: assign(() => ({error: undefined}))
                }],
                [TRANSITION_AUTHENTICATE]: [{
                    target: STATE_AUTHENTICATE,
                    actions: assign(() => ({error: undefined})),
                }]
            }
        },
        [STATE_PENDING_FORGOT_PWD]: {
            invoke: {
                src: context => forgotPassword(services, context.email),
                onDone: {target: STATE_FORGOT_PWD_DONE},
                onError: {
                    target: STATE_FORGOT_PWD,
                    actions: assign((_, event) => ({error: event.data.response}))
                }
            }
        },
        [STATE_FORGOT_PWD_DONE]: {
            on: {
                ...DATA_INPUT_EVENT,
                [TRANSITION_PROCEED]: [{
                    target: STATE_FORGOT_PWD,
                    cond: context => context.email
                }],
            }
        },
        [STATE_RESET_PWD]: {
            always: [{
                target: STATE_INIT_RESET_PWD,
                cond: context => !context.passwordRules
            }],
            on: {
                ...DATA_INPUT_EVENT,
                [TRANSITION_PROCEED]: [{
                    target: STATE_PENDING_RESET_PWD,
                    cond: context => context.email && context.code && context.newPassword
                }],
                [TRANSITION_AUTHENTICATE]: STATE_AUTHENTICATE,
            }
        },
        [STATE_INIT_RESET_PWD]: {
            invoke: {
                src: () => Promise.all([
                    passwordRules(services),
                    token && forgot(services, token),
                ].filter(Boolean)),
                onDone: {
                    target: STATE_RESET_PWD,
                    actions: assign((_, event) => {
                        const [passwordRules, forgotResponse] = event.data;

                        return {passwordRules, email: forgotResponse?.email};
                    }),
                }
            }
        },
        [STATE_PENDING_RESET_PWD]: {
            invoke: invokeLogin(STATE_RESET_PWD, context => resetPassword(services, context.email, context.code, context.newPassword), STATE_RESET_PWD)
        },
        [STATE_PENDING_AUTHENTICATE]: {
            invoke: invokeLogin(STATE_AUTHENTICATE, context => signIn(services, context.email, context.password)),
            exit: [assign(context => context.password = undefined)]
        },
        [STATE_AUTHENTICATE]: {
            on: {
                ...DATA_INPUT_EVENT,
                [TRANSITION_PROCEED]: [{
                    target: STATE_PENDING_AUTHENTICATE,
                    cond: context => context.email && context.password
                }],
                [TRANSITION_FORGOTTEN_PASSWORD]: STATE_FORGOT_PWD
            },
        },
        [STATE_PWD_CHANGE]: {
            on: {
                ...DATA_INPUT_EVENT,
                [TRANSITION_PROCEED]: {
                    target: STATE_PENDING_PWD_CHANGE,
                    cond: context => context.email && context.newPassword
                }
            },
            always: {
                target: STATE_INIT_PWD_CHANGE,
                cond: context => !context.passwordRules,
            }
        },
        [STATE_INIT_PWD_CHANGE]: {
            invoke: {
                src: () => passwordRules(services),
                onDone: {
                    actions: assign((_, event) => ({passwordRules: event.data})),
                    target: STATE_PWD_CHANGE
                }
            },
        },
        [STATE_PENDING_PWD_CHANGE]: {
            invoke: invokeLogin(STATE_PWD_CHANGE, context => changePwdChallenge(services, context.email, context.newPassword))
        },
        [STATE_SUCCESS]: {
            type: 'final',
            entry: context => window.location.href = context.redirect
        }
    }
})

export default createAuthenticationMachine;
