import React, {useState} from 'react'
import {Translate, useTranslation} from '@stockfiller/i18n'
import {Box, Button, TextField, Typography} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import CocoPasswordSetter from '../password/CocoPasswordSetter'
import FormFragment from './FormFragment';

const isFieldError = error => error?.body?.fields?.length > 0

const ResetPwd = ({passwordRules, onSubmit, onValueChange, email, error}) => {
    const [pendingPwd, setPendingPwd] = useState("");
    const [translate] = useTranslation();
    const [isPasswordValid, setPasswordValid] = useState(false);
    const [searchParams] = useSearchParams()

    return (
        <FormFragment onSubmit={() => {
            onValueChange("email", email);
            onValueChange("code", searchParams.get("code"));
            onValueChange("newPassword", pendingPwd);
            onSubmit();
        }}>
            <Typography variant="h2"><Translate id="resetPasswordView.title" /></Typography>
            <TextField disabled
                id="email"
                label={<Translate id="resetPasswordView.fields.email" />}
                name="email"
                required
                type="email"
                value={email}/>
            <CocoPasswordSetter autoFocus
                error={isFieldError(error) && error.body.fields.includes("password")}
                label={<Translate id="resetPasswordView.fields.password" />}
                onChange={setPendingPwd}
                onPasswordValid={isValid => setPasswordValid(isValid)}
                passwordRules={passwordRules}
                required
                value={pendingPwd}/>
            { error && (
                <Typography paragraph variant="body2">
                    <em>
                        <Translate
                            data={isFieldError(error) && ({ field: translate(`resetPasswordView.fields.${error.body.fields[0]}`).toLowerCase() })}
                            id={`resetPasswordView.errors.${error.statusCode}`} />
                    </em>
                </Typography>
            )}
            <Box>
                <Button
                    color="primary"
                    disabled={!isPasswordValid}
                    type="submit"
                ><Translate id="resetPasswordView.buttons.submit" /></Button>
            </Box>
        </FormFragment>
    )
}
export default ResetPwd
