import React from 'react'
import { Box, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { cls, Email, Phone } from '@stockfiller/ui'
import { Translate, useTranslation } from '@stockfiller/i18n'

const styles = theme => ({
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "300px",
        padding: "16px",
        "& > *": {
            marginTop: "16px",
            marginBottom: "16px"
        },
        "& a": {
            color: theme.palette.primary.dark,
            textDecoration: "none",
            display:"block"
        }
    },
    icon: {
        display: "block",
        color: theme.palette.primary.main
    }
})

const GenericError = ({classes, className, errorCode}) => {
    const [translate] = useTranslation()
    const supportEmail = translate('supportContactDetails.email')
    const supportPhone = translate('supportContactDetails.phone')

    return (
        <div>
            <Paper className={cls([className, classes.content])} elevation={3}>
                <Typography variant="h2"><Translate id="errorView.genericErrorViewTitle" /></Typography>
                <FontAwesomeIcon
                    className={classes.icon}
                    icon={ faBug }
                    size="8x"
                />
                <Box>
                    <Typography paragraph variant="body1"><Translate id="errorView.genericErrorSupportPointer" /></Typography>
                    <Typography variant="body1">
                        <Phone phone={supportPhone}>
                            <Translate data={{supportPhone}} id="generalTerms.supportPhone" />
                        </Phone>
                        <Email email={supportEmail}>
                            <Translate data={{supportEmail}} id="generalTerms.supportEmail" />
                        </Email>
                    </Typography>

                </Box>
            </Paper>
        </div>

    )
}
export default withStyles(styles)(GenericError)
