import React from 'react'
import {Box, Button, TextField, Typography} from '@mui/material'
import {Translate, useTranslation} from '@stockfiller/i18n'
import FormFragment from './FormFragment';

const ForgottenPwd = ({error, onSubmit, onAuthenticate, onValueChange}) => {
    const [translate] = useTranslation();
    const supportEmail = translate("supportContactDetails.email");

    return (
        <FormFragment onSubmit={values => {
            onValueChange("email", values.email.trim());
            onSubmit();
        }}>
            <Typography variant="h2"><Translate id="forgottenPasswordView.title"/></Typography>
            <TextField autoFocus
                error={Boolean(error)}
                id="email"
                label={<Translate id="forgottenPasswordView.fields.email"/>}
                name="email"
                required/>
            {error && (
                <Typography color={error ? "error" : "initial"} paragraph variant="body2">
                    <em><Translate data={{ supportEmail }} id={`forgottenPasswordView.errors.${error.statusCode}`}/></em>
                </Typography>
            )}
            <Box>
                <Typography><Translate id="forgottenPasswordView.description"/></Typography>
            </Box>
            <Box>
                <Button
                    color="primary"
                    type="submit"
                >
                    <Translate id="forgottenPasswordView.buttons.submit"/>
                </Button>
                <Button
                    onClick={() => onAuthenticate()}
                    size="small"
                    variant="text"
                >
                    <Translate id="forgottenPasswordView.buttons.signIn"/>
                </Button>
            </Box>
        </FormFragment>
    );
}

export default ForgottenPwd
