import React, { useMemo, useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { findKey, mapValues } from '@stockfiller/core'
import { CircularProgress } from '@mui/material'
import createAuthenticationMachine, {
    TRANSITION_PROCEED,
    TRANSITION_FORGOTTEN_PASSWORD,
    TRANSITION_AUTHENTICATE,
    STATE_AUTHENTICATE,
    STATE_ERROR,
    STATE_FORGOT_PWD,
    STATE_PWD_CHANGE,
    STATE_RESET_PWD,
    EVENT_NEW_INPUT,
    STATE_FORGOT_PWD_DONE,
    STATE_SUCCESS,
    STATE_PENDING_AUTHENTICATE
} from './authFSM'
import ForgottenPwd from './view-fragments/ForgottenPwd'
import SignIn from './view-fragments/SignIn'
import ResetPwd from './view-fragments/ResetPwd'
import PwdChallenge from './view-fragments/PwdChallenge'
import ForgottenPwdDone from "./view-fragments/ForgottenPwdDone";
import {useServices} from "client/serviceContext";
import GenericError from 'client/GenericError'
import { routeConfig } from 'client/config/routes'

const onValueChange = send => (id, value) => send(EVENT_NEW_INPUT, {field: id, value: value})

const generateDeepLinks = routeMap => Object.assign(...mapValues(routeMap, v => ({[v]: v})))

const Authentication = ({ state : routeState = STATE_AUTHENTICATE}) => {
    const services = useServices();
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { search } = useLocation()
    const stateRoutes = useMemo(() => generateDeepLinks(routeConfig), [])
    const [state, send] = useMachine(() => createAuthenticationMachine(services, generateDeepLinks(routeConfig), searchParams.get("token")))
    const [errorState, setErrorState] = useState(false)
    const [contentVisible, setContentVisible] = useState(false)

    // Outside routing allowed only on entry.
    useEffect(() => {
        state.value === "idle" && window.setTimeout(
            () => send(routeState),1000
        )
    }, [routeState, state, send])

    // Sync internal state with history when necessary
    useEffect(() => {
        stateRoutes.hasOwnProperty(state.value) &&
            navigate({
                pathname: findKey(routeConfig, value => value === state.value),
                search: search
            })
    }, [state, search, navigate, stateRoutes])

    useEffect(() => {
        setContentVisible(true)
        state.value === STATE_ERROR &&
            window.setTimeout(() => setContentVisible(false), 1000) &&
            window.setTimeout(() => {
                setErrorState(true)
                setContentVisible(true)}, 2000)

    },[state])

    return (
        <div >
            { !errorState ? (
                <>
                    { state.value === STATE_AUTHENTICATE && (
                        <SignIn
                            error={state.context.error}
                            onForgottenPwd={() => send(TRANSITION_FORGOTTEN_PASSWORD)}
                            onSubmit={() => send(TRANSITION_PROCEED)}
                            onValueChange={onValueChange(send)}
                        />
                    )}

                    { state.value === STATE_FORGOT_PWD && (
                        <ForgottenPwd
                            error={state.context.error}
                            onAuthenticate={() => send(TRANSITION_AUTHENTICATE)}
                            onSubmit={() => send(TRANSITION_PROCEED)}
                            onValueChange={onValueChange(send)}
                        />
                    )}

                    { state.value === STATE_RESET_PWD && (
                        <ResetPwd
                            email={state.context.email}
                            error={state.context.error}
                            onSubmit={() => send(TRANSITION_PROCEED)}
                            onValueChange={onValueChange(send)}
                            passwordRules={state.context.passwordRules}
                        />
                    )}

                    { state.value === STATE_PWD_CHANGE && (
                        <PwdChallenge
                            error={state.context.error}
                            onSubmit={() => send(TRANSITION_PROCEED)}
                            onValueChange={onValueChange(send)}
                            passwordRules={state.context.passwordRules}
                        />
                    )}

                    { state.value === STATE_FORGOT_PWD_DONE && (
                        <ForgottenPwdDone
                            email={state.context.email}
                            onBack={() => send(TRANSITION_PROCEED)}
                        />
                    )}

                    { state.value === (STATE_PENDING_AUTHENTICATE || STATE_SUCCESS) && (
                        <CircularProgress />
                    )}

                </>
            ) : <GenericError sx={{opacity: contentVisible ? 1 : 0}} />  }
        </div>
    );
}

export default Authentication
