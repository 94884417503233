import React from "react";
import {
    consoleHandler,
    csrfMiddleware,
    fetchHandler,
    httpClient,
    logger as loggerFactory,
    loggerMiddleware,
    nullHandler
} from "@stockfiller/core";
import {XSRF_TOKEN} from "../common/xsrf";

const createHttpClient = (logger) => httpClient({
    handler: fetchHandler(fetch),
    middlewares: [
        loggerMiddleware({logger}),
        csrfMiddleware({cookieName: XSRF_TOKEN, headerName: XSRF_TOKEN}),
    ]
});

const createLogger = () => loggerFactory({
    handler: process.env.NODE_ENV !== "production" ? consoleHandler() : nullHandler(),
});

export const createServices = data => {
    const logger = createLogger();

    return ({
        httpClient: createHttpClient(logger),
        logger,
        createCognitoUserEncodedData: username =>
            // Imported from index HTML file from file amazon-cognito-advanced-security-data.min.js
            // See: https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-settings-adaptive-authentication.html
            // eslint-disable-next-line no-undef
            AmazonCognitoAdvancedSecurityData.getData(username, process.env.AWS_COGNITO_USER_POOL_ID, data.clientId),
    });
}
const serviceContext = React.createContext({});
export const ServiceProvider = serviceContext.Provider;
export const useServices = () => React.useContext(serviceContext);
