import React from 'react'
import { Locale } from '@stockfiller/ui';
import {Translate, useTranslation} from '@stockfiller/i18n'
import {Box, Button, Link, TextField, Typography} from '@mui/material'
import {DEFAULT_LANGUAGE} from "../../config/localization/langConfig";
import FormFragment from './FormFragment';

const SignIn = ({error, onValueChange, onSubmit, onForgottenPwd}) => {
    const [translate] = useTranslation();
    const [,I18n] = useTranslation()
    const supportEmail = translate("supportContactDetails.email");

    return (
        <FormFragment onSubmit={values => {
            onValueChange("email", values.email.trim());
            onValueChange("password", values.password);
            onSubmit();
        }}>
            <Typography variant="h2"><Translate id="signInView.title"/></Typography>
            <Typography variant="body1"><Translate id="signInView.message" /></Typography>
            <TextField
                autoFocus
                error={Boolean(error)}
                id="email"
                label={<Translate id="signInView.fields.email"/>}
                name="email"
                required
                type="email"
            />
            <TextField
                error={Boolean(error)}
                id="password"
                label={<Translate id="signInView.fields.password"/>}
                name="password"
                required
                type="password"
            />
            {error && (
                <Typography paragraph variant="body2">
                    <em><Translate data={{supportEmail}} id={`signInView.errors.${error.statusCode}`}/></em>
                </Typography>
            )}
            <Box sx={{mt: "auto"}}>
                <Button
                    onClick={() => onForgottenPwd()}
                    size="small"
                    sx={{justifyContent: "flex-start"}}
                    variant="text"
                ><Translate id="signInView.buttons.forgotPassword"/></Button>
                <Button
                    color="primary"
                    type="submit"
                ><Translate id="signInView.buttons.submit"/></Button>
            </Box>
            {!error && (
                <Box>
                    <Typography variant="body1">
                        <Translate id="signInView.texts.dontHaveAccount"/>
                        <span> </span>
                        <Link href={getSignUpLink(I18n?.language ?? DEFAULT_LANGUAGE.locale)} target="_blank">
                            <Translate id="signInView.links.getStarted"/>
                        </Link>
                    </Typography>
                </Box>
            )}
        </FormFragment>
    );
}

const getSignUpLink = (locale) => {
    const swedish = "https://www.stockfiller.com/boka-demo-valj/"
    const english = "https://www.stockfiller.com/en/choose-demo-type/"

    switch (locale) {
    case Locale.Swedish:
        return swedish;
    case Locale.German:
    case Locale.French:
    case Locale.Dutch:
    case Locale.English:
    default:
        return english;
    }
}

export default SignIn;
