import React from "react";
import {Translate, useTranslation} from "@stockfiller/i18n";
import { SfLink } from '@stockfiller/front-end'
import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Email, Phone} from "@stockfiller/ui";
import pkg from "../../../../package.json"

const HELP_CENTER_DEF = {
    'sv-SE': 'http://help.stockfiller.com/'
}

const styles = ({
    help: {
        lineHeight: "1.5",
        '& a': {
            textDecoration: "none",
            color: "inherit",
            '&:hover': {
                textDecoration: "underline"
            }
        },
    }
})

/**
 * Displays a help block
 */
const Help = ({classes}) => {
    const [translate, i18n] = useTranslation()
    const currentLanguage = i18n.language

    const phone = translate("supportContactDetails.phone");
    const email = translate("supportContactDetails.email");

    return (
        <Typography className={classes.help} variant="body1">
            { HELP_CENTER_DEF[currentLanguage] ? (
                <>
                    <Translate id={"supportContactDetails.helpcenterMessage"} />
                    &nbsp;
                    <span style={{fontWeight: "bold"}}>
                        <SfLink absolute to={HELP_CENTER_DEF[currentLanguage]}>
                            <Translate id={"supportContactDetails.helpcenterLink"} />
                        </SfLink>
                    </span>
                </>
            ) : (
                <>
                    <Phone phone={phone}>
                        <Translate data={{supportPhone: phone}} id="generalTerms.supportPhone"/>
                    </Phone>
                    <br/>
                    <Email email={email}>
                        <Translate data={{supportEmail: email}} id="generalTerms.supportEmail"/>
                    </Email>
                </>
            )}
            <br />
            <Translate data={{version: pkg.version}} id="generalTerms.version"/>
        </Typography>
    )
};

export default withStyles(styles)(Help);
