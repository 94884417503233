import "./polyfills";
import {getNavigatorLocale, initI18n} from '@stockfiller/i18n';
import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from "./client/serviceWorker";
import App from "./client/app/App";
import {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from 'client/config/localization/langConfig';

(async () => {
    const locale = getNavigatorLocale(window)
    const i18n = await initI18n(locale, DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES)

    render(
        <React.StrictMode>
            <BrowserRouter>
                <App data={window.data} i18n={i18n}/>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );

    serviceWorker.register();

    if (module.hot) {
        module.hot.accept();
    }
})()


