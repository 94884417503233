import React from 'react'
import { Translate } from '@stockfiller/i18n';
import { SfLink } from '@stockfiller/front-end';
import { useMediaMatchDevice } from "@stockfiller/ui"
import { Box, Divider, Grid, Typography, styled } from '@mui/material'
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import CorporatePanel from './CorporatePanel';
import { Help, LanguageSelect } from './tools';

const LinkReset = styled(Typography)({
    "& a":{
        color: "inherit",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    }
})

const AppBar = ({height}) => (
    <Grid container position={"absolute"} sx={{background: "transparent", height: `${height}px`, top: 0}} width="100%">
        <Grid item padding={4} sx={{color: theme => theme.palette.common.white}} xs={6}>
            <LinkReset variant="body1">
                <SfLink absolute to={"https://www.stockfiller.com"}>
                    <Icon icon={faArrowLeft} />&nbsp;
                    <Translate id={"generalTerms.goBack"} />
                </SfLink>
            </LinkReset>
        </Grid>
        <Grid container item justifyContent={"flex-end"} paddingX={4} paddingY={2} sx={{color: theme => theme.palette.common.black}} xs={6}>
            <Grid item>
                <LanguageSelect />
            </Grid>
        </Grid>
    </Grid>
)

const UserInterface = ({children}) => {
    const { isMobile } = useMediaMatchDevice()
    const BAR_HEIGHT = 80
    return (
        <Box
            height="100vh"
            sx={{
                background: theme => !isMobile ?
                    `linear-gradient(90deg, ${theme.palette.primary.dark} 50%, ${theme.palette.common.white} 50%)` :
                    "transparent"
            }}
            width="100%"
        >
            <AppBar height={BAR_HEIGHT} />
            <Grid
                alignItems={!isMobile && "flex-start"}
                container
                direction={"row"}
                sx={{
                    width: "100%",
                    margin: "auto",
                    maxWidth: "1500px",
                    height: "100vh"
                }}
            >
                <Grid
                    container
                    direction={"row"}
                    item
                    md={6}
                    sx={{
                        color: theme => theme.palette.common.white,
                        paddingTop: "80px",
                    }}
                    xs={12}
                >
                    <CorporatePanel clearance={2*BAR_HEIGHT} />
                </Grid>
                <Grid
                    container
                    item
                    md={6}
                    sx={{
                        backgroundColor: theme => theme.palette.common.white,
                        paddingTop: !isMobile && `${BAR_HEIGHT}px`,
                        color: theme => theme.palette.common.black,
                    }}
                    xs={12}
                >
                    <Box
                        alignItems={isMobile ? "flex-start" : "center"}
                        display={"flex"}
                        height={!isMobile ? `calc(100vh - ${2 * BAR_HEIGHT}px)` : undefined}
                        justifyContent={"center"}
                        paddingY={2}
                        width="100%"
                    >
                        {children}
                    </Box>
                </Grid>
                <Grid item paddingX={4} paddingY={2} sx={{height: "80px", width: "100%", backgroundColor: theme => isMobile ? theme.palette.common.white : "transparent", color: theme => isMobile ? theme.palette.common.black : theme.palette.common.white}}>
                    { isMobile && <Divider /> }
                    <Help />
                </Grid>
            </Grid>
        </Box>
    )}

export default UserInterface
