import { PasswordValidationCriteria } from "@stockfiller/omega-sdk"

// see: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
/** Creates rules to be sent into our password strength component */
export const rulesFactory = (translate, cognitoRules) => [
    // order matters! earlier rules will be displayed first as a hint
    cognitoRules.requireNumber && {
        fulfilled: PasswordValidationCriteria.requireNumbers.fulfilled,
        hint: translate("passwordStrengthHints.digit"),
    },
    cognitoRules.requireUppercase && {
        fulfilled: PasswordValidationCriteria.requireUppercase.fulfilled,
        hint: translate("passwordStrengthHints.uppercase"),
    },
    cognitoRules.requireLowercase && {
        fulfilled: PasswordValidationCriteria.requireLowercase.fulfilled,
        hint: translate("passwordStrengthHints.lowercase"),
    },
    cognitoRules.requireSpecialChars && {
        fulfilled: PasswordValidationCriteria.requireSymbols.fulfilled,
        hint: translate("passwordStrengthHints.specialChars"),
    },
    cognitoRules.minimumLength && {
        fulfilled: password => PasswordValidationCriteria.minimumLength.fulfilled(password, cognitoRules.minimumLength),
        hint: translate("passwordStrengthHints.length", {length: cognitoRules.minimumLength}),
    },
].filter(x => x);
