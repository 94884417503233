/*
 * Contains information about what languages we support
 */

// The default language for the application
export const DEFAULT_LANGUAGE = {
    name: "English",
    locale: "en",
    translation: async () => {
        const [translation] = await Promise.all([
            import(/* webpackChunkName: "enJson" */ "./translations/en.json"),
        ]);
        return translation
    }
};

// All languages that is supported
// @see http://www.lingoes.net/en/translator/langcode.htm for full list of locales
export const SUPPORTED_LANGUAGES = [
    DEFAULT_LANGUAGE,
    {
        name: "Svenska",
        locale: "sv-SE",
        translation: async () => {
            const [translation] = await Promise.all([
                import(/* webpackChunkName: "svJson" */ "./translations/sv-SE.json"),
            ]);

            return translation
        }
    },
    {
        name: "Nederlands",
        locale: "nl-NL",
        translation: async () => {
            const [translation] = await Promise.all([
                import(/* webpackChunkName: "nlJson" */ "./translations/nl-NL.json"),
            ]);

            return translation
        }
    },
    {
        name: "Deutsch",
        locale: "de-DE",
        translation: async () => {
            const [translation] = await Promise.all([
                import(/* webpackChunkName: "deJson" */ "./translations/de-DE.json"),
            ]);

            return translation
        }
    },
    {
        name: "Français",
        locale: "fr-FR",
        translation: async () => {
            const [translation] = await Promise.all([
                import(/* webpackChunkName: "frJson" */ "./translations/fr-FR.json"),
            ]);

            return translation
        }
    }
];
