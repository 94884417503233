import React, {useState} from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Translate } from '@stockfiller/i18n';
import CocoPasswordSetter from '../password/CocoPasswordSetter'
import FormFragment from './FormFragment';

const PwdChallenge = ({onSubmit, onValueChange, error, passwordRules}) => {
    const [pendingPwd, setPendingPwd] = useState("")
    const [isPasswordValid, setPasswordValid] = useState(false);

    return (
        <FormFragment onSubmit={values => {
            onValueChange("newPassword", values.newPassword);
            onSubmit();
        }}>
            <h2>
                <Translate id="passwordChallengeView.title"/>
            </h2>
            <CocoPasswordSetter
                autoFocus
                error={Boolean(error)}
                id="newPassword"
                label={<Translate id="passwordChallengeView.fields.password"/>}
                name="newPassword"
                onChange={setPendingPwd}
                onPasswordValid={isValid => setPasswordValid(isValid)}
                passwordRules={passwordRules}
                required
                value={pendingPwd}/>
            { error && <Typography paragraph variant="body2"><em><Translate id={`passwordChallengeView.errors.${error.statusCode}`} /></em></Typography> }
            <Box>
                <Button
                    color="primary"
                    disabled={!isPasswordValid}
                    type="submit"
                >
                    <Translate id="passwordChallengeView.buttons.submit"/>
                </Button>
            </Box>
        </FormFragment>
    )
}
export default PwdChallenge
