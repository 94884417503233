import React from 'react'
import { Translate } from '@stockfiller/i18n';
import { stockfillerLogoWhite, useMediaMatchDevice } from '@stockfiller/ui'
import { Box, Grid, Typography } from "@mui/material";
import productGlimpseImg from "img/glimpse.png";
import stockfillerIcon from 'img/stockfiller_icon_opacity.svg'


const CorporatePanel = ({clearance}) => {
    const {isMobile } = useMediaMatchDevice()

    return (
        <>
            <Grid
                alignItems={"center"}
                container
                flexGrow={1}
                item
                justifyContent={"flex-end"}
                sx={{
                    backgroundImage: `url("${stockfillerIcon}")`,
                    backgroundPosition: "60% 50%",
                    backgroundSize: "350%",
                    width: "100%",
                    height: isMobile ? "fit-content" : `calc(100vh - ${clearance}px)`
                }}
            >
                <Box
                    height={"fit-content"}
                    paddingLeft={8}
                    paddingRight={0}
                    paddingY={4}
                >
                    <img alt="logotype" src={stockfillerLogoWhite} style={{maxWidth: "230px"}} />
                    { !isMobile && (
                        <>
                            <Box paddingBottom={4} width="300px">
                                <Typography sx={{paddingTop: 2, paddingBottom: 1}} variant="h3"><Translate id="corporatePanel.title" /></Typography>
                                <Typography varaint="body1"><Translate id="corporatePanel.promoText" /></Typography>
                            </Box>
                            <img
                                alt="illustration"
                                src={productGlimpseImg}
                                style={{
                                    maxWidth: "100%",
                                    borderRadius: "12px 0 0 12px",
                                    float: "right",
                                    maxHeight: "45vh"
                                }} />
                        </>
                    )}
                </Box>
            </Grid>
        </>
    )
}

export default CorporatePanel
