import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { changeTranslationLanguage, findTranslation, useTranslation } from '@stockfiller/i18n'
import { getFlagByLocale } from '@stockfiller/ui'
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'client/config/localization/langConfig'

const LocaleOption = ({lang}) => (
    <React.Fragment>
        <span
            style={{
                backgroundImage: `url(${getFlagByLocale(lang.locale)})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block",
                width: "22px",
                height: "18px",
            }}
        />
        {<span style={{margin: "8px"}}>{lang.name}</span>}
    </React.Fragment>
)

const styles = theme => ({
    icon: {
        fill: theme.palette.primary.main
    }
})

const LanguageSelect = ({classes}) => {
    const [ , i18n] = useTranslation()

    return (
        <FormControl
            className={classes.box}
            fullWidth={false}
        >
            <Select
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                onChange={event => changeTranslationLanguage(event.target.value)}
                sx={{backgroundColor: theme => theme.palette.common.white, color: theme => theme.palette.common.black}}
                value={findTranslation(SUPPORTED_LANGUAGES, i18n.language)?.locale ?? DEFAULT_LANGUAGE.locale}
                variant="outlined"
            >
                { SUPPORTED_LANGUAGES.map(lang => (
                    <MenuItem
                        key={lang.locale}
                        value={lang.locale}
                    >
                        <LocaleOption lang={lang}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default withStyles(styles)(LanguageSelect)
