import { createTheme } from '@mui/material/styles';

export const createLocalTheme = theme => createTheme({
    ...theme,
    components: {
        ...theme.components,
        MuiButton: {
            ...theme.components.MuiButton,
            defaultProps: {
                ...theme.components.MuiButton?.defaultProps,
                fullWidth: true
            }
        },
        MuiCssBaseline: {
            ...theme.components.MuiCssBaseline,
            styleOverrides: {
                ...theme.components.MuiCssBaseline?.styleOverrides,
                body: {
                    ...theme.components.MuiCssBaseline?.styleOverrides?.body,
                    backgroundColor: theme.palette.primary.dark
                }
            }
        }
    }
})
