import { useEffect } from "react"
import { useLocation } from "react-router-dom"


const trackGoogleAnalytics = (location) => {
    const {pathname} = location
    if(window.gtag){
        window.gtag("config", process.env.RAZZLE_GOOGLE_ANALYTICS_ID, {page_path: pathname})
    }
}

export const useTracking = () => {
    const location = useLocation()

    useEffect(() => {
        if(window && location){
            trackGoogleAnalytics(location)
        }
    }, [location])
}
