import {
    STATE_AUTHENTICATE,
    STATE_FORGOT_PWD,
    STATE_FORGOT_PWD_DONE,
    STATE_RESET_PWD
} from "client/authentication/authFSM";

export const routeConfig = {
    "/login/sign-in": STATE_AUTHENTICATE,
    "/login/forgot-password": STATE_FORGOT_PWD,
    "/login/forgot-password/done": STATE_FORGOT_PWD_DONE,
    "/login/reset-password": STATE_RESET_PWD,
    "/*": STATE_AUTHENTICATE
}
