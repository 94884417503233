import React from 'react'
import { Form } from "@stockfiller/ui"
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    flexbox: {
        padding: theme.spacing(8),
        width: "100%",
        maxWidth: "500px",
        "& > *": {
            marginBottom: theme.spacing(1),
        },
    }
})

const FormFragment = ({children, classes, onSubmit}) => (
    <Form className={classes.flexbox} onSubmit={onSubmit}>
        {children}
    </Form>
)

export default withStyles(styles)(FormFragment)
