import React from 'react'
import { useTranslation } from '@stockfiller/i18n'
import { SetPasswordField } from '@stockfiller/ui'
import { rulesFactory } from 'client/config/passwordConfig'

const CocoPasswordSetter = ({label, onBlur, onChange, value, onPasswordValid, passwordRules, ...props}) => {
    const [translate] = useTranslation();

    return (
        <SetPasswordField
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            onPasswordValid={onPasswordValid}
            rules={rulesFactory(translate, passwordRules)}
            value={value}
            {...props}
        />
    )
}
export default CocoPasswordSetter
