import React from "react";
import { SfThemeProvider } from "@stockfiller/ui";
import { I18nProvider } from "@stockfiller/i18n";
import {Route, Routes} from "react-router-dom";
import {createServices, ServiceProvider} from "../serviceContext";
import {routeConfig} from "../config/routes";
import Authentication from "../authentication/Authentication";
import { useTracking } from "../useTracking";
import UserInterface from "./UserInterface";
import {createLocalTheme} from "client/config/theme";

const App = ({i18n, data}) => {
    useTracking()

    return (
        <I18nProvider i18n={i18n}>
            <SfThemeProvider theme={createLocalTheme}>
                <ServiceProvider value={createServices(data)}>
                    <UserInterface>
                        <Routes>
                            {Object.keys(routeConfig).map(k => (
                                <Route element={<Authentication state={routeConfig[k]}/>} key="authentication" path={k} />
                            ))}
                        </Routes>
                    </UserInterface>
                </ServiceProvider>
            </SfThemeProvider>
        </I18nProvider>
    );
}

export default App;
